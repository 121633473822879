
import {
    defineComponent, nextTick, PropType, ref
} from 'vue';
import deviceInfoSettingDialog from '@/components/view/pm/device-info-setting-dialog';
import { device } from '@/data';
import {
    titleType,
    labelType,
    gradeType,
    noramlDetailForm,
    getProjectDetailForm,
    getDeviceType,
    publicDeviceType
} from './util';

export default defineComponent({
    components: {
        deviceInfoSettingDialog
    },
    props: {
        build: {
            type: String,
            default: ''
        },
        type: {
            type: String as PropType<ProjectType>,
            default: 'community'
        }
    },
    setup(props) {
        const detailForm = props.build === 'community' ? getProjectDetailForm(props.type) : noramlDetailForm;
        const deviceDetail = JSON.parse(localStorage.getItem('detail') as string);
        const projectType = deviceDetail.ProjectType;
        const { MAC, SipType, Config } = deviceDetail;
        detailForm.forEach((key, index) => {
            if (key.type === publicDeviceType) {
                detailForm[index].text = deviceDetail[detailForm[index].name] ? deviceDetail[detailForm[index].name].join(';') : '';
            } else if (key.type === gradeType && Number(deviceDetail[key.name]) === device.publicDevice) {
                if (Number(deviceDetail.IsAllBuild) === device.allBuild) {
                    detailForm[1].text = WordList.AllBuildings;
                } else if (Number(deviceDetail.IsAllDepartment) === device.allBuild) {
                    detailForm[1].text = WordList.AllDepartments;
                }
            } else {
                detailForm[index].text = deviceDetail[detailForm[index].name];
            }
        });

        const isShowDeviceInfoSettingDialog = ref(false);
        const closeInfo = () => {
            isShowDeviceInfoSettingDialog.value = false;
        };

        const initForm = ref({
            MAC: deviceDetail.MAC,
            SipType: deviceDetail.SipType,
            Config: deviceDetail.Config
        });

        const showSettingDialog = () => {
            isShowDeviceInfoSettingDialog.value = true;
            const tempData = JSON.parse(localStorage.getItem('detail') as string);
            initForm.value = {
                MAC: tempData.MAC,
                SipType: tempData.SipType,
                Config: tempData.Config
            };
        };

        return {
            detailForm,
            titleType,
            labelType,
            gradeType,
            MAC,
            closeInfo,
            isShowDeviceInfoSettingDialog,
            initForm,
            showSettingDialog,
            getDeviceType,
            publicDeviceType,
            projectType
        };
    }
});
