const titleType = 1;
const labelType = 2;
const gradeType = 3;
const publicDeviceType = 4;

const noramlDetailForm = [{
    label: WordList.DeviceDetailDetailBasic,
    text: '',
    type: titleType,
    name: 'Basic'
}, {
    label: WordList.TabelPersonDeviceInHtmlLocation,
    text: '',
    type: labelType,
    name: 'Location'
}, {
    label: WordList.TabelPersonDeviceInHtmlMAC,
    text: '',
    type: labelType,
    name: 'MAC'
}, {
    label: WordList.MulListUpdateListTanleAccount,
    text: '',
    type: labelType,
    name: 'SipAccount'
}, {
    label: WordList.DeviceListTanleLastConnected,
    text: '',
    type: labelType,
    name: 'LastConnection'
}, {
    label: WordList.DeviceDetailDetailProduct,
    text: '',
    type: titleType,
    name: 'Product'
}, {
    label: WordList.DeviceDetailDetailFirmware,
    text: '',
    type: labelType,
    name: 'Firmware'
}, {
    label: WordList.DeviceDetailDetailHardware,
    text: '',
    type: labelType,
    name: 'Hardware'
}, {
    label: WordList.DeviceDetailDetailNetwork,
    text: '',
    type: titleType,
    name: 'Network'
}, {
    label: WordList.DeviceDetailDetailConnectType,
    text: '',
    type: labelType,
    name: 'SipType'
}, {
    label: WordList.DeviceDetailDetailIPAddress,
    text: '',
    type: labelType,
    name: 'IPAddress'
}, {
    label: WordList.DeviceDetailDetailSubnetMask,
    text: '',
    type: labelType,
    name: 'SubnetMask'
}, {
    label: WordList.DeviceDetailDetailGateway,
    text: '',
    type: labelType,
    name: 'Gateway'
}, {
    label: WordList.DeviceDetailDetailPrimaryDNS,
    text: '',
    type: labelType,
    name: 'PrimaryDNS'
}, {
    label: WordList.DeviceDetailDetailSecondaryDNS,
    text: '',
    type: labelType,
    name: 'SecondaryDNS'
}];

// 社区详情多出类型、所属者
const getProjectDetailForm = (type: ProjectType) => [{
    label: WordList.DeviceDetailDetailBasic,
    text: '',
    type: titleType,
    name: 'Basic'
}, {
    label: WordList.DeviceDetailDetailBuilds,
    text: '',
    type: publicDeviceType,
    name: type === 'community' ? 'BuildNames' : 'DepartmentNames'
}, {
    label: WordList.TabelPersonDeviceInHtmlLocation,
    text: '',
    type: labelType,
    name: 'Location'
}, {
    label: WordList.TabelPersonDeviceInHtmlMAC,
    text: '',
    type: labelType,
    name: 'MAC'
}, {
    label: WordList.MulListUpdateListTanleAccount,
    text: '',
    type: labelType,
    name: 'SipAccount'
}, {
    label: WordList.DeviceListTanleLastConnected,
    text: '',
    type: labelType,
    name: 'LastConnection'
}, {
    label: WordList.TabelKeyInHtmlType,
    text: '',
    type: gradeType,
    name: 'Grade'
}, {
    label: WordList.TabelPersonDeviceInHtmlOwner,
    text: '',
    type: labelType,
    name: 'Owner'
}, {
    label: WordList.DeviceDetailDetailProduct,
    text: '',
    type: titleType,
    name: 'Product'
}, {
    label: WordList.DeviceDetailDetailFirmware,
    text: '',
    type: labelType,
    name: 'Firmware'
}, {
    label: WordList.DeviceDetailDetailHardware,
    text: '',
    type: labelType,
    name: 'Hardware'
}, {
    label: WordList.DeviceDetailDetailNetwork,
    text: '',
    type: titleType,
    name: 'Network'
}, {
    label: WordList.DeviceDetailDetailConnectType,
    text: '',
    type: labelType,
    name: 'SipType'
}, {
    label: WordList.DeviceDetailDetailIPAddress,
    text: '',
    type: labelType,
    name: 'IPAddress'
}, {
    label: WordList.DeviceDetailDetailSubnetMask,
    text: '',
    type: labelType,
    name: 'SubnetMask'
}, {
    label: WordList.DeviceDetailDetailGateway,
    text: '',
    type: labelType,
    name: 'Gateway'
}, {
    label: WordList.DeviceDetailDetailPrimaryDNS,
    text: '',
    type: labelType,
    name: 'PrimaryDNS'
}, {
    label: WordList.DeviceDetailDetailSecondaryDNS,
    text: '',
    type: labelType,
    name: 'SecondaryDNS'
}];

const getDeviceType = (type: ProjectType): {
    [key in number]: string;
} => {
    const deviceType = {
        1: WordList.RDeivicePublicAreaDevice,
        2: WordList.RDeiviceBuildDevice,
        3: WordList.RDeiviceUserDevice
    };
    const officeDeviceType = {
        1: WordList.RDeivicePublicAreaDevice,
        2: WordList.DepartmentDevice,
        3: WordList.PeopleDevice
    };
    const projectDeviceType = {
        community: deviceType,
        office: officeDeviceType
    };
    return projectDeviceType[type];
};

export default null;
export {
    titleType,
    labelType,
    gradeType,
    noramlDetailForm,
    getProjectDetailForm,
    getDeviceType,
    publicDeviceType
};
